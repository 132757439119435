{
  "components": {
    "design-system": {
      "navigation": {
        "NavigationBar": {
          "create-account": "Vytvoriť profil",
          "login": "Prihlásiť sa"
        }
      }
    },
    "DonateBlock": {
      "body": "Gefällt Ihnen das Projekt Wahlrechner? Wir würden uns freuen, wenn Sie uns unterstützen, damit wir das Service auch bei den kommenden Wahlen anbieten können.",
      "title": "Unterstützung für den Wahlrechner"
    },    
    "FooterMultiWord": {
      "body-about-voting-calculator": "Über den Wahlrechner",
      "body-comunal-voting": "Komunálné voľby",
      "body-created-by-ngos": "Erstellt durch eine Zusammenarbeit von gemeinnützigen Organisationen.",
      "body-data-protection": "Datenschutz",
      "body-methodoogy": "Methodologie der Fragenentwicklung",
      "body-presidential-voting": "Prezidentské voľby",
      "body-senate-voting": "Senátní volby",
      "body-support-calculator": "Unterstützung",
      "body-who-to-vote": "Der Wahlrechner ist ein unparteiischer Wahlhelfer.",
      "imprint": "Impressum",
      "links": "Links",
      "title-more-about-elections": "Mehr zu den Wahlen"
    },
    "QuestionCandidateFilter": {
      "title-candidate-filter": "Filtern nach Parteien",
      "select-all": "Alle auswählen"
    },
    "QuestionTagFilter": {
      "title-filter-by-theme": "Filtern nach Themen",
      "select-all": "Alle auswählen"
    },
    "WantToKnowMore": {
      "more-about-topic": "Mehr zum Thema"
    }
  },
  "routes": {
    "comparison": {
      "ComparisonGrid": {
        "answers": "Antworten",
        "my": "Meine"
      },
      "ComparisonPage": {
        "back-to-main-page": "Zurück zur Hauptseite",
        "comparison": "Vergleich",
        "my-match": "Meine Übereinstimmung",
        "set-comparison": "Vergleich einstellen"
      }
    },
    "data-protection": {
      "DataProtectionPage": {
        "title": "Datenschutz"
      }
    },
    "error": {
      "ErrorPage": {
        "go-to-main-page": "Gehe zur Hauptseite",
        "this-page-does-not-exist": "Diese Seite existiert nicht.",
        "unknown-error": "Unbekannter Fehler"
      }
    },
    "guide": {
      "GuidePage": {
        "agree": "Dafür",
        "back-to-main-page": "Zurück zur Hauptseite",
        "disagree": "Dagegen",
        "double-weight": " Diese Antwort hat dann doppeltes Gewicht bei der Berechnung des Ergebnisses.",
        "first-question": "Zur ersten Frage",
        "guide": "Anleitung",
        "important": "wichtig für mich",
        "next-step": "Nächster Schritt",
        "not-included": "Die Frage wird dann nicht in die Berechnung des Ergebnisses einbezogen.",
        "previous-step": "Vorheriger Schritt",
        "skip": "überspringen",
        "skip-guide": "Anleitung überspringen",
        "skip-question": "Ist man bei einem Thema nicht sicher, oder will aus einem anderen Grund nicht antworten, kann die Frage mit einem Klick (rechts bzw. rechts oben) auf “überspringen” übersprungen werden.",
        "text-0": "Wenn eine Partei die Frage nicht beantwortet hat (z.B. Stimmenthaltung bei Abstimmung), wird dies mit 0 Punkten bewertet.",
        "text-answer-button": "Antwortmöglichkeiten:",
        "text-important": "Wird eines der Themen als besonders wichtig empfunden, kann es mit einem Stern markiert werden.",
        "text-method": "Wenn eine Antwort mit einer Partei übereinstimmt, wird im Übereinstimmungswert 1 Punkt angerechnet. Wenn die Antwort nicht, übereinstimmt wird 1 Punkt abgezogen."
      }
    },
    "index": {
      "IndexPage": {
        "czech-version": {
          "link": "tschechische Version",
          "prefix": "In der Zwischenzeit können Sie sich {0}",
          "suffix": " des Wahlrechners ansehen."
        },
        "disclaimer": "Mit dem Absenden stimmen Sie dem Erhalt von Neuigkeiten über den Wahlrechner zu.",
        "empty-email-error": "Das Feld darf nicht leer sein",
        "error": "Etwas ist schief gelaufen :( Versuchen Sie es erneut.",
        "input-label": "E-Mail",
        "primary-text": "Wir bereiten einen Wahlrechner für die Wahlen zum Nationalrat der Slowakischen Republik 2023 vor.",
        "secondary-text": "Hinterlassen Sie uns Ihre E-Mail-Adresse und wir informieren Sie, sobald der Rechner verfügbar ist.",
        "subscribe-button-label": "Benachrichtigen Sie mich",
        "success": "Wir werden Sie informieren!",
        "title": "Wahlrechner 2024"
      }
    },
    "profile": {
      "AuthPageVue": {
        "create-it": "Vytvorte si ho",
        "create-profile-email": "Vytvoriť profil pomocou e-mailu",
        "link-title": "Podmienkami ochrany osobných údajov",
        "log-in": "Prihláste sa",
        "notification": "Vaše osobné údaje budú spracovávané za účelom tvorby užívateľského profilu, a to v súlade s",
        "opinion ": "Sledujte názorový vývoj vo svojom profile",
        "question-one": "Už máte profil?",
        "question-two": "Ešte nemáte profil?",
        "registration": "Vytvoriť profil",
        "title-close": "Zavrieť",
        "use-email": "Pomocou e-mailu"
      },
      "EmailAuthPageVue": {
        "confirmation": "Zaslať potvrdzovací e-mail",
        "create-profile": "Vytvoriť profil",
        "log-in": "Prihlásiť sa",
        "message": "Zadajte svoju e-mailovú adresu",
        "message-verification-first": "Do e-mailovej schránky Vám zašleme e-mail s overovacím odkazom. Registráciu úspešne dokončíte po kliknutí naň.",
        "message-verification-second": "Do e-mailovej schránky Vám zašleme e-mail s overovacím odkazom.",
        "title-close": "Zavrieť"
      },
      "EmailFormPageVue": {
        "about-spam": "Ak ste e-mail s overovacím odkazom nedostali, skontrolujte prosím zložku spamu alebo nevyžiadanú poštu.",
        "confirm-info": "Potvrďte registráciu na Vašom e-maile",
        "confirm-title": "Registrácia čaká na Vaše potvrdenie",
        "error-email": "E-mailová adresa nie je správnom tvare",
        "error-empty-field": "Pole nesmie byť prázdne",
        "login-info": "Do e-mailovej schránky Vám zašleme e-mail s overovacím odkazom.",
        "login-title": "Prihlásiť sa",
        "notification": "Zadajte svoju emailovú adresu",
        "notification-info": "Potvrďte prihlásenie na Vašom e-maile",
        "notification-title": "Prihlásenie čaká na Vaše potvrdenie",
        "registration-info": "Do e-mailovej schránky Vám zašleme e-mail s overovacím odkazom. Registráciu úspešne dokončíte po kliknutí naň.",
        "registration-title": "Vytvoriť profil",
        "send-confirm": "Zaslať potvrdzovací email",
        "title-back": "Naspäť",
        "title-close": "Zavrieť"
      },
      "ProfilePage": {
        "main-title": "Moje kalkulačky",
        "message": "Žiadne ďalšie vyplnené kalkulačky v roku 2023"
      },
      "ProfileSettingsPage": {
        "button-delete": "Odstrániť profil",
        "button-delete-profile": "Zmazať profil a dáta",
        "button-save": "Uložiť zmeny",
        "button-save-profile": "Ponechať profil a dáta",
        "confirm-delete-profile": "Naozaj chcete zmazať profil?",
        "log-out": "Odhlásiť sa",
        "message-part-one": "Prečítajte si o tom na",
        "message-part-two": "Zásady ochrany dát",
        "notification-delete-profile": "Zmazaním profilu prídete permanentne o uložené kalkulačky.",
        "title-data": "Ako nakladáme s dátami?",
        "title-settings": "Nastavenie profilu"
      },
      "SocialMediaConnectCompponent": {
        "facebook": "Pokračovať cez Facebook",
        "google": "Pokračovať cez Google"
      }
    },
    "question": {
      "QuestionBottomBar": {
        "important-for-me": "Wichtig für mich",
        "no": "Dagegen",
        "yes": "Dafür"
      },
      "QuestionPage": {
        "back-to-main-page": "Zurück zur Hauptseite",
        "instructions-long": "Anleitung",
        "instructions-short": "Anleitung",
        "next-question-long": "Nächste Frage",
        "next-question-short": "Nächste",
        "previous-question-long": "Vorherige Frage",
        "previous-question-short": "Vorherige",
        "recapitulation-long": "Übersicht",
        "recapitulation-short": "Übersicht",
        "skip-question-long": "Frage überspringen",
        "skip-question-short": "Überspringen"
      }
    },
    "questions-methodology": {
      "QuestionsMethodologyPageVue": {
        "title": "Methodik für die Auswahl und Entwicklung von Fragen"
      }
    },
    "recap": {
      "RecapPage": {
        "back-to-main-page": "Zurück zur Hauptseite",
        "display-results": "Ergebnisse anzeigen",
        "hint-text": "Hier kann man die gewählten Antworten und deren Gewichtung überprüfen und gegebenenfalls anpassen.",
        "questions": "Fragen",
        "recapitulation": "Übersicht"
      },
      "RecapQuestionCard": {
        "display-details": "Details anzeigen",
        "important-for-me": "Wichtig für mich",
        "no": "Dagegen",
        "without-answer": "Ohne Antwort",
        "yes": "Dafür"
      }
    },
    "result": {
      "ResultCandidateCard": {
        "display-details": "Details anzeigen",
        "what-candidate-say": "Was sagen die Kandidaten über sich selbst?",
        "eu-party": "Europäische Partei",
        "eu-group": "Europäische Gruppe"
      },
      "ResultCardContacts": {
        "contacts": "Kontakte"
      },
      "ResultCategory": {
        "hide-candidates": "Kandidaten ausblenden",
        "show-all-candidates": "Alle Kandidaten anzeigen"
      },
      "ResultPage": {
        "back-to-main-page": "Zurück zur Hauptseite",
        "back-to-start": "Zurück zum Anfang",
        "candidates-answers": "Antworten der Kandidaten",
        "compare-answers": "Vergleiche die Antworten",
        "display-not-advancing-candidates": "Nicht weitergeleitete Kandidaten anzeigen",
        "in-table": "in der Tabelle",
        "fill-calc": "Fülle den Wahlrechner aus",
        "my-match": "Meine Übereinstimmung",
        "recapitulation": "Rekapitulation",
        "share": "Teilen",
        "something-went-wrong": "Etwas ist schief gelaufen",
        "something-went-wrong-text": "Leider kann im Moment nicht geteilt werden. Bitte versuchen Sie es später erneut.",
        "text-and-answer": "und mindestens eine Frage beantworten oder",
        "text-at-least-one-answer": "Um das Ergebnis anzuzeigen muß man mindestens eine Frage beantworten",
        "text-display-answers": "den Vergleich der Antworten der Kandidaten ansehen",
        "text-in-time": "Sledujte, ako sa Vaše názory a výsledky (ne)menia v čase.",
        "text-save-calc": "Uložte si kalkulačku a vyplňte ju aj viackrát, a to pre každej voľby.",
        "text-table": "Einen vollständigen Überblick über alle Kandidaten und deine Antworten (Abstimmungen) findest du",
        "you-may": "Du kannst"
      },
      "ResultShareModal": {
        "share-facebook": "Auf Facebook teilen",
        "share-now": "Teilen jetzt...",
        "share-title": "Wahlrechner 2024",
        "share-twitter": "Auf X / Twitter teilen",
        "text-copy": "...oder klicke hier, um den Link zu kopieren.",
        "text-do-it-too": "Schau dir meine Ergebnisse an und füll sie auch aus!",
        "text-my-match": "Laut dem Wahlrechner habe ich die größte Übereinstimmung mit den folgenden Ergebnissen:"
      }
    },
    "share": {
      "SharePage": {
        "fill-again": "Erneut ausfüllen.",
        "my-match": "So ist es bei mir herausgekommen.",
        "text-fill-again": "Wenn Sie den Wahlrechner auch ausfüllen möchten, klicken Sie auf „Erneut ausfüllen“ in der oberen rechten Ecke."
      }
    }
  }
}
