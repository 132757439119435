
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
  
import IconBadge from '@/components/design-system/icons/IconBadge.vue'
import StackComponent from '@/components/design-system/layout/StackComponent.vue';

import {
  vkiLogoInFavour,
  vkiLogoAgainst,
} from '@/components/design-system/icons';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body">
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Schlechtes Beispiel</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Gutes Beispiel</p>
</stack-component>
<h2>1. Die Frage muss auf ein Thema bezogen sein, auf das die gewählten Politiker:innen Einfluss haben.</h2>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Österreich sollte die EU verlassen (Frage bei einer Landtagswahl).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Österreich sollte aus der EU austreten (Frage bei den Nationalratswahlen).</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Ich möchte, dass Wien flächendeckend Tempo 30 einführt. (Frage bei den Gemeinderatswahlen).</p>
</stack-component>
<h2>2. Die Frage sollte mit Ja oder Nein beantwortet werden können und nicht suggestiv gestellt sein.</h2>
<p>Bei der Gestaltung von Fragen müssen wir uns vorstellen können, dass die Menschen sowohl Ja- als auch Nein-Antworten wählen werden. Die Frage sollte auch nicht suggestiv (leitend) sein.</p>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Sollte die Verkehrssituation in St. Pölten verbessert werden? (Impliziert, dass die Verkehrssituation in St. Pölten Verbesserung braucht)</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Ich möchte, dass Wien flächendeckend Tempo 30 einführt.</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Sollte mindestens 1% des Verkehrsbudgets der Stadt für den Radverkehr bereitgestellt werden?</p>
</stack-component>
<h2>3. Die Fragen sollten wichtig sein</h2>
<p>Wir berücksichtigen beispielsweise Themen, für die der Rat/das Parlament die meisten öffentlichen Gelder bereitstellt.</p>
<p>Wir wählen Themen aus, die für alle Wählergruppen relevant sind (Alter, Einkommen, Interessen).</p>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Sollte sich die Stadt bei Frau M. Lorenz und Herrn Cejthamra für die Äußerungen während dieser Konfrontation entschuldigen?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Würden Sie für die Abschaffung der Garantie und den automatischen Eintritt in die zweite Säule stimmen?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Würden Sie für das Vertrauen in die Regierung und die Beibehaltung des Euro stimmen?</p>
</stack-component>
<h2>4. Die Frage sollte so spezifisch wie möglich sein.</h2>
<p>Die Antwort soll nach Ende einer Legislaturperiode bzw. vor der nächsten Wahl überprüft werden können.</p>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Die Landesregierung soll den öffentlichen Verkehr weiterentwickeln.</p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Die Landesregierung soll mehr Gelder in den öffentlichen Verkehr investieren als in der vergangenen Legislaturperiode.</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Sollte der Preis für Fahrkarten im öffentlichen Nahverkehr für Senior:innen und Studierende um 10% gesenkt werden?</p>
</stack-component>
<h2>5. Die Frage muss kurz und klar sein</h2>
<p>Wir möchten, dass die Fragen auch ohne viel Vorwissen verstanden werden. Deshalb formulieren wir sie in maximal 20 Wörtern und beschränken die jeweiligen Zusatzinfos auf 50 Wörter.</p>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Würden Sie stimmen für:</p>
<p>die Abschaffung des Sozialgeldes für Alleinerziehende, die Kürzung des Geburtsgeldes nur für Eltern mit geringem Einkommen, einen einheitlichen Gesamtbetrag des Elterngeldes (220 000 CZK) und eine größere Flexibilität bei der Wahl der Höhe und der Dauer des Elterngeldes, die Kürzung des Betreuungsgeldes, die Kürzung des Arbeitslosengeldes und die Abschaffung der Möglichkeit eines Zuschusses zum Arbeitslosengeld in Höhe des Mindesteinkommens, die Einführung eines Existenzgründerzuschusses, die Verschärfung der Bedingungen für den Anspruch auf Krankengeld und Mutterschaftsgeld für Selbständige?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Ich bin mit der Einführung von Drehkreuzen in der U-Bahn einverstanden.</p>
</stack-component>
<h2>6. Fragen sollen auch ohne Zusatzinformation Sinn ergeben</h2>
<p>Viele Menschen lesen nur die Frage selbst und nicht die dazugehörige Beschreibung. Es kann vorkommen, dass die Beschreibung auf einem mobilen Gerät nicht angezeigt wird.</p>
<br>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Glauben Sie, dass es sinnvoll ist, sich ähnlichen Verkehrsprojekten wie in Tirol anzuschließen?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Ich bin für die Erhebung einer Maut für die Einfahrt von Privat-PKW in den 1. Wiener Gemeindebezirk.</p>
</stack-component>
<h2>7. Wir bevorzugen Fragen in der positiven Form und vermeiden die verwirrende doppelte Negativform</h2>
<p>Das folgende Beispiel zeigt, dass die doppelte Verneinung irreführend und ungenau ist.
<br></p>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Würden Sie nicht zustimmen, dass es nicht unvernünftig wäre, Windräder zur Stromerzeugung in Oberösterreich nicht zu bauen?</p>
<p>Tatsächlich ist hier nicht klar, womit der Befragte nicht einverstanden ist:</p>
<p>a) nein, es sollte nicht gebaut werden,</p>
<p>b) nicht einverstanden, es sollte gebaut werden.</p>
<p>Eine richtig formulierte Frage müsste lauten: Soll die Landesregierung den Bau von Windrädern zur Stromerzeugung in Oberösterreich verhindern?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Kleine Gemeinden sollten zu größeren Einheiten zusammengelegt werden.</p>
<p>Kleine Gemeinden sollten nicht zu größeren Einheiten zusammengelegt werden.</p>
<h2>8. Die Frage muss in einfacher Sprache formuliert sein</h2>
<p>Die Fragen werden so formuliert, dass sie von möchlichst allen Wähler:innen unterschiedlichen Alters, Bildungsgrads oder Herkunft, verstanden werden können.</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Die Wiener Ringstraße soll dauerhaft für den privaten Auto-Verkehr gesperrt werden.</p>
<br>
<p><icon-badge :icon="vkiLogoAgainst" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-secondary-fg))&#x27;"></icon-badge> Die permanente Exklusion des privaten Autoverkehrs auf der Wiener Ringstraße soll etabliert werden.</p>
<h2>9. Wir wählen manchmal auch Themen aus, die interessant, aber vielleicht nicht ganz so wichtig sind.</h2>
<p>Das soll zur Auflockerung der manchmal vielleicht etwas trockenen Themen dienen.</p>
<stack-component spacing="extra-small">
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Würden Sie für den Vorschlag stimmen, den Abgeordneten farblich zu signalisieren, wie sie abstimmen sollen?</p>
<p><icon-badge :icon="vkiLogoInFavour" :background-color="&#x27;white&#x27;" :color="&#x27;rgb(var(--color-primary-fg))&#x27;"></icon-badge> Würden Sie für das Gesetz “Václav Havel hat zu Freiheit und Demokratie beigetragen” stimmen?</p>
</stack-component>
</div>
</template>
