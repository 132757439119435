
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><p>In diesem Dokument finden Sie Informationen darüber, welche Informationen über Sie von uns, dem Verein KohoVolit.eu, ID-Nr.: 22841890, mit Sitz in Vinice 347, Plasy, Tschechische Republik, eingetragen im Bundesregister beim Landgericht in Pilsen, Aktenzeichen: L 5397, als Inhaber der personenbezogenen Daten verarbeitet werden. Sie werden auch erfahren, zu welchen Zwecken wir Ihre personenbezogenen Daten verarbeiten und aus welchen Quellen wir diese Daten erhalten. Im letzten Abschnitt informieren wir Sie darüber, welche Rechte Sie bei der Verarbeitung Ihrer personenbezogenen Daten haben und wie Sie diese ausüben können.</p>
<h2>Welche personenbezogenen Daten verarbeiten wir und zu welchen Zwecken?</h2>
<p><strong>Die Basisversion des Wahlrechners verwendet keine Cookies und sammelt keine Daten über Sie.</strong> Wenn Sie den Rechner anonym nutzen, werden also keine personenbezogenen Daten erhoben.</p>
<p>Wenn Sie im Wahlrechner ein <strong>Benutzerprofil anlegen</strong>, werden folgende personenbezogene Daten gespeichert:</p>
<ul>
<li>E-Mail Adresse,</li>
<li>Vor- und Nachname (optional),</li>
<li>Ihr ständiger Wohnsitz (optional),</li>
<li>Daten zu Ihren Antworten und daraus abgeleitete Präferenzen.</li>
</ul>
<p>Wir verarbeiten diese personenbezogenen Daten zur Erfüllung des Vertrages, insbesondere zur Pflege des Nutzerprofils mit Ihnen, bis zur Löschung Ihres Nutzerprofils. Gleichzeitig verarbeiten wir die Daten über Ihre Antworten und abgeleiteten Präferenzen auf der Grundlage Ihrer Einwilligung als Ausnahmen für die Verarbeitung besonderer Datenkategorien.</p>
<p>Im Falle der Anmeldung über <strong>Facebook-, Google- und Twitter-Profile</strong> werden folgende personenbezogene Daten gespeichert:</p>
<ul>
<li>Name,</li>
<li>Profilfoto,</li>
<li>Wohnanschrift (optionale Daten).</li>
</ul>
<p>Wir verarbeiten diese personenbezogenen Daten zur Vertragserfüllung, insbesondere um Ihnen zu ermöglichen, sich über Ihr Profil bei Dritten anzumelden, bis Ihr Benutzerprofil gelöscht wird.</p>
<p>Im Falle der <strong>Weitergabe Ihrer Antworten</strong> werden wir darüber hinaus Daten über Ihre Antworten und die daraus abgeleiteten Präferenzen übermitteln. Wir verarbeiten diese Daten auf der Grundlage Ihrer Einwilligung zum Zweck der Weitergabe Ihrer Antworten für einen Zeitraum von einem Monat nach der Weitergabe.</p>
<p>Wenn Sie uns Ihre Zustimmung geben, <strong>gelegentlich Werbemitteilungen zu erhalten</strong>, werden wir Ihre E-Mail-Adresse speichern und für diese Zwecke verarbeiten. So werden Sie beispielsweise über die Einführung des Wahlrechners für die bevorstehenden Wahlen informiert. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie sich an die oben genannten Kontaktpersonen oder direkt in der zugesandten E-Mail wenden. Wir verarbeiten personenbezogene Daten, bis Sie Ihre Einwilligung widerrufen.</p>
<h2>Aus welchen Quellen erhalten wir personenbezogene Daten?</h2>
<p>Die personenbezogenen Daten, die wir verarbeiten, stammen in erster Linie von Ihnen selbst, aus Ihrer Nutzung der Website des Wahlrechners und aus dem Ausfüllen der Daten.</p>
<p>Andere personenbezogene Daten erhalten wir im Rahmen unserer Geschäftstätigkeit, die hauptsächlich aus den von Ihnen bereitgestellten Daten abgeleitet werden, insbesondere Daten über Ihre voraussichtliche Wahlpräferenz und die Übereinstimmung mit den Präferenzen des von Ihnen gewählten Kandidaten.</p>
<p>Wir können weitere personenbezogene Daten von Dritten erhalten, z. B. wenn Sie sich über Dienste von Dritten in den Wahlrechner einloggen.</p>
<h2>Links zu Datenschutzrichtlinien Dritter</h2>
<p>Wahlrechner kann Websites von Drittanbietern enthalten, die Dienstleistungen im Zusammenhang mit unserer Plattform anbieten. Bitte beachten Sie, dass diese Websites eigene Datenschutzrichtlinien und Datenverarbeitungspraktiken haben. Wir empfehlen Ihnen, diese Richtlinien zu überprüfen, bevor Sie persönliche Informationen bereitstellen. Weitere Informationen finden Sie in den Datenschutzrichtlinien der folgenden Seiten:</p>
<ul>
<li>Darujme: <a href="https://www.darujme.cz/projekt/1209495/zpracovani-osobnich-udaju?locale=en">Datenschutzerklärung</a></li>
</ul>
<h2>Welche Rechte haben Sie bei der Verarbeitung personenbezogener Daten?</h2>
<p>Ebenso wie wir bei der Verarbeitung Ihrer personenbezogenen Daten Rechte und Pflichten haben, haben auch Sie bei der Verarbeitung Ihrer personenbezogenen Daten bestimmte Rechte, die in den folgenden Abschnitten erläutert werden. Sie können alle diese Rechte ausüben und sich bei Fragen oder Beschwerden an uns wenden: <a href="mailto:info@kohovolit.eu">info@kohovolit.eu</a>. Wir werden Ihre Anfrage immer so schnell wie möglich, spätestens jedoch innerhalb eines Monats, bearbeiten. In Ausnahmefällen können wir diese Frist um weitere zwei Monate verlängern, insbesondere aufgrund der Komplexität Ihres Anliegens, und wir werden Sie darüber informieren.</p>
<p>Im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten stehen Ihnen insbesondere die folgenden Rechte zu:</p>
<ol>
<li>
<p>Das Recht auf Widerruf der Einwilligung</p>
<p>Sie haben das Recht, Ihre Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widerrufen. Sie können dies tun, indem Sie eine E-Mail an unsere Adresse senden. Der Widerruf der Einwilligung berührt jedoch nicht die Rechtmäßigkeit der vor der Einwilligung erfolgten Verarbeitung.</p>
</li>
<li>
<p>Recht auf Auskunft</p>
<p>Sie haben das Recht, von uns eine Bestätigung darüber zu erhalten, ob wir Ihre personenbezogenen Daten verarbeiten oder nicht.</p>
<p>Wenn wir Ihre personenbezogenen Daten verarbeiten, haben Sie außerdem das Recht, Auskunft über den Zweck und den Umfang der Verarbeitung, die Empfänger der Daten, die Dauer der Verarbeitung, das Recht auf Berichtigung, Löschung, Einschränkung der Verarbeitung und auf Widerspruch gegen die Verarbeitung, das Recht auf Beschwerde bei einer Aufsichtsbehörde und die Quellen der personenbezogenen Daten zu verlangen (diese Informationen sind bereits in diesem Dokument enthalten).</p>
<p>Sie können von uns auch eine Kopie der von uns verarbeiteten personenbezogenen Daten verlangen. Die erste Kopie stellen wir Ihnen kostenlos zur Verfügung, weitere können bereits gebührenpflichtig sein. Der Umfang der bereitgestellten Daten kann eingeschränkt werden, um die Rechte und Freiheiten anderer nicht zu beeinträchtigen.</p>
</li>
<li>
<p>Recht auf Berichtigung</p>
<p>Sie haben das Recht, von uns die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Je nach Zweck der Verarbeitung können Sie auch das Recht haben, unvollständige personenbezogene Daten vervollständigen zu lassen, auch durch Abgabe einer zusätzlichen Erklärung.</p>
</li>
<li>
<p>Recht auf Löschung</p>
<p>Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn:</p>
<p>Wir benötigen Ihre personenbezogenen Daten nicht mehr für die Zwecke, für die sie erhoben oder verarbeitet wurden;
Sie widerrufen die Einwilligung, auf deren Grundlage die personenbezogenen Daten verarbeitet wurden, und es gibt keinen weiteren Grund für die Verarbeitung; Sie legen Widerspruch gegen die Verarbeitung ein, und es gibt keine weiteren zwingenden Gründe für die Verarbeitung, oder Sie legen Widerspruch gegen die Verarbeitung zu Zwecken der Direktwerbung ein; personenbezogene Daten werden unter Verstoß gegen das Gesetz verarbeitet.</p>
<p>Sie können dieses Recht jedoch nicht ausüben, wenn die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen oder zur Wahrnehmung von Aufgaben, die uns im öffentlichen Interesse übertragen wurden, oder zur Begründung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.</p>
</li>
<li>
<p>Recht auf Einschränkung der Verarbeitung</p>
<p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn:</p>
<p>Sie bestreiten die Richtigkeit Ihrer personenbezogenen Daten; in diesem Fall können Sie die Einschränkung der Verarbeitung verlangen, bis die Richtigkeit der personenbezogenen Daten überprüft ist;</p>
<p>die Verarbeitung gegen das Gesetz verstößt und Sie anstelle der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen;</p>
<p>wir Ihre personenbezogenen Daten für die Zwecke, für die sie erhoben oder verarbeitet wurden, nicht mehr benötigen, Sie sie aber zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen;</p>
<p>Sie haben gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch eingelegt; in diesem Fall können Sie eine Einschränkung der Verarbeitung beantragen, bis geprüft wurde, ob unsere berechtigten Interessen überwiegen.</p>
</li>
<li>
<p>Recht auf Übertragbarkeit</p>
<p>Sie haben das Recht, eine Kopie Ihrer personenbezogenen Daten zu erhalten, die wir auf der Grundlage Ihrer Einwilligung oder zur Erfüllung eines Vertrags automatisiert verarbeiten. Wir werden diese Daten in einem gängigen und maschinenlesbaren Format an Sie oder an einen von Ihnen benannten Verantwortlichen übermitteln, sofern dies technisch machbar ist. Der Umfang der übermittelten Daten kann eingeschränkt werden, um die Rechte und Freiheiten anderer Personen nicht zu beeinträchtigen.</p>
</li>
<li>
<p>Recht auf Widerspruch gegen die Verarbeitung</p>
<p>Sie haben das Recht, gegen die Verarbeitung Ihrer personenbezogenen Daten, die wir auf der Grundlage unserer berechtigten Interessen verarbeiten, Widerspruch einzulegen. Wir werden die Verarbeitung Ihrer Daten einstellen, wenn es keine anderen zwingenden Gründe für die Verarbeitung gibt oder wenn die Verarbeitung nicht für die Begründung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist oder wenn Sie der Verarbeitung zu Zwecken der Direktwerbung widersprechen.</p>
</li>
<li>
<p>Recht auf Einreichung einer Beschwerde</p>
<p>Neben der Ausübung der oben genannten Rechte können Sie auch eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen, und zwar beim Amt für den Schutz personenbezogener Daten mit Sitz in Pplk. Sochora 27, 170 00 Prag 7</p>
</li>
</ol>
</div>
</template>
